import {AuthSrv} from "/@lib@/core/user.js";
import {OLoginMainStepProps, UserSelfSrv} from "/@lib@/core/userSelf.js";
import {DOM} from "/@lib@/commons/xml/dom.js";
import {Desk} from "/@lib@/commons/desk.js";

/**
 * Librairie pure js de gestion des écrans relatifs aux users.
 *
 */

/**
 * Ecran de login.
 * Champs du formulaire attendus:
 * - name=account
 * - name=password
 * Bouton de fonction "perte de mot de passe" : class="pwdLost"
 * Zone de message / info : class="message"
 *    data-type=['', 'pending']
 * Zone de message d'erreur : class="error"
 */

export interface OLoginFormOptions {
	startMessage?: string
	defaultAccount?: string
	fixedAccount?: string

	/** Passage de l'écran de login à celui du renouvellement du mot de passe. */
	showRenewPwd?: () => HTMLFormElement

	/** Retour à l'écran de login */
	hideRenewPwd?: (renewForm: HTMLFormElement) => void

	/** Messages personnalisables */
	msgs?: OUserSelfFormsMsgs
}

export function initLoginForm(form: HTMLFormElement, userSelfServer: UserSelfSrv, authServer: AuthSrv, options: OLoginFormOptions = {}): void {
	checkForm(form);
	const accountInput = form.elements.namedItem('account') as HTMLInputElement;
	const passwordInput = form.elements.namedItem('password') as HTMLInputElement;
	const pwdLostElt = form.querySelector('.pwdLost') as HTMLElement;
	const msgElt = form.querySelector('.message') as HTMLElement;
	const errorElt = form.querySelector('.error') as HTMLElement;

	if (!userSelfServer.config.pwdLostUrl)
		DOM.setHidden(pwdLostElt, true);

	accountInput.value = passwordInput.value = '';
	accountInput.required = passwordInput.required = true;

	if (options.fixedAccount) {
		accountInput.value = options.fixedAccount;
		accountInput.readOnly = true;
	} else {
		accountInput.value = options.defaultAccount || authServer.currentAccount || "";
	}
	if (accountInput.value) passwordInput.focus();
	else accountInput.focus();

	if (options.startMessage) showMsg(msgElt, options.startMessage);

	accountInput.onchange = resetValidity;
	accountInput.onkeydown = resetValidity;
	passwordInput.onchange = resetValidity;
	passwordInput.onkeydown = resetValidity;

	if (pwdLostElt) {
		pwdLostElt.onclick = async (event) => {
			showMsg(errorElt);
			event.preventDefault();
			if (!accountInput.checkValidity()) {
				form.reportValidity();
				return;
			}
			showMsg(msgElt, "Veuillez patienter...", null, null, "pending");
			const response = await userSelfServer.pwdLost({nickOrAccount: accountInput.value});
			showMsg(msgElt);
			const result = response ? response.result : "otherFailure";
			switch (result) {
			case 'accountNotFound':
				accountInput.setCustomValidity(getMsg("Ce compte n'existe pas.", 'accountNotFound', options.msgs));
				form.reportValidity();
				break;
			case 'datasNotAvailable' :
				showMsg(errorElt, "Nous ne disposons pas des informations nécessaires pour vous envoyer les instructions.", 'datasNotAvailable', options.msgs);
				break;
			case 'instructionsSent' :
				accountInput.setCustomValidity('');
				showMsg(msgElt, "Les instructions pour renouveler votre mot de passe vous ont été envoyées.", 'instructionsSent', options.msgs);
				break;
			case 'otherFailure':
				showMsg(errorElt, "Le système de validation des mots de passe est indisponible. Veuillez essayer ultérieurement.", 'otherFailure', options.msgs);
				break;
			default :
				console.log(`Unknow auth result: ${result}'`);
				break;
			}
		};
	}

	form.onsubmit = async function (event) {
		event.preventDefault();
		const props: OLoginMainStepProps = {nickOrAccount: accountInput.value, currentPwd: passwordInput.value};
		// Session maximale pour Electron, limité par le serveur
		if (Desk.electron) props.cookieMaxAge = 999999999;
		const {result, secondaryResults} = await userSelfServer.login(props);
		switch (result) {
		case 'logged' :
			showMsg(errorElt);
			accountInput.setCustomValidity('');
			passwordInput.setCustomValidity('');
			localStorage.setItem("login:lastAccount", props.nickOrAccount);
			break;
		case 'invalidPassword':
			showMsg(errorElt);
			passwordInput.setCustomValidity(getMsg("Ce mot de passe est incorrect.", 'invalidPassword', options.msgs));
			form.reportValidity();
			break;
		case 'obsoletPassword':
			if (options.showRenewPwd) {
				showMsg(errorElt);
				const renewPwdFrom = options.showRenewPwd();
				initRenewPwdForm(renewPwdFrom, userSelfServer, authServer, {
					fixedAccount: accountInput.value,
					startMessage: getMsg("Votre mot de passe a expiré, vous devez le renouveler.", 'obsoletPassword', options.msgs),
					renewPwdDone: () => {
						if (options.hideRenewPwd) options.hideRenewPwd(renewPwdFrom);
						return form;
					},
					showRenewPwd: options.showRenewPwd,
					msgs: options.msgs
				});
			} else {
				showMsg(errorElt, "Votre mot de passe a expiré, vous devez le renouveler.", 'obsoletPassword', options.msgs);
			}
			break;
		case 'accountNotFound':
			showMsg(errorElt);
			accountInput.setCustomValidity(getMsg("Ce compte n'existe pas.", 'accountNotFound', options.msgs));
			form.reportValidity();
			break;
		case 'accountDisabled':
			const ts = secondaryResults ? secondaryResults.disabledEndDt : 0;
			if (ts > (Date.now() - 5000)) {
				const lang = document.documentElement.lang || "fr";
				const date = new Date(ts);
				const localeDate = date.toLocaleDateString(lang);
				const localeTime = date.toLocaleTimeString(lang, {hour: "numeric", minute: "numeric"});
				showMsg(errorElt, `Ce compte a été temporairement désactivé. Il sera de nouveau actif le ${localeDate} vers ${localeTime}.`, 'accountDisabled', options.msgs);
			} else {
				showMsg(errorElt, "Votre compte est désactivé.", 'accountDisabled', options.msgs);
			}
			break;
		case 'otherFailure':
			showMsg(errorElt, "Le système de validation des mots de passe est indisponible. Veuillez essayer ultérieurement.", 'otherFailure', options.msgs);
			break;
		default :
			console.log(`Unknow auth result: '${result}'`);
			break;
		}
	};
}


/**
 * Ecran de renouvellemment du mot de passe avec demande de (re)saisie du mot de passe actuel.
 * Champs du formulaire attendus:
 * - [optionnel] name=account
 * - name=currentPwd
 * - name=newPwd
 * - name=confirmPwd
 *
 * Zone de message / info : class="message"
 * Zone de message d'erreur : class="error"
 */
export interface ORenewPwdFormOptions {
	startMessage?: string
	fixedAccount?: string

	/**
	 * Callback lorsque le renouvellement du password a été réalisé.
	 * Si un formulaire est retourné, l'écran de login est réactivé avec ce formulaire.
	 */
	renewPwdDone?: () => void | HTMLFormElement

	/** Messages personnalisables */
	msgs?: OUserSelfFormsMsgs

	/** Interne. Pour permettre les cycles en cas de retour au login de renewPwdDone(). */
	showRenewPwd?: () => HTMLFormElement
}

export function initRenewPwdForm(form: HTMLFormElement, userSelfServer: UserSelfSrv, authServer: AuthSrv, options: ORenewPwdFormOptions) {
	checkForm(form);
	const accountInput = form.elements.namedItem('account') as HTMLInputElement;
	const currentPwdInput = form.elements.namedItem('currentPwd') as HTMLInputElement;
	const newPwdInput = form.elements.namedItem('newPwd') as HTMLInputElement;
	const confirmPwdInput = form.elements.namedItem('confirmPwd') as HTMLInputElement;
	const errorElt = form.querySelector('.error') as HTMLElement;

	let account = {value: options.fixedAccount || authServer.currentAccount};

	if (accountInput) {
		if (account.value) {
			accountInput.value = account.value;
			accountInput.readOnly = true;
		} else {
			accountInput.required = true;
			account = accountInput;
		}
	}

	if (options.startMessage) showMsg(form.querySelector('.message') as HTMLElement, options.startMessage);

	currentPwdInput.value = newPwdInput.value = confirmPwdInput.value = '';
	currentPwdInput.required = newPwdInput.required = confirmPwdInput.required = true;
	currentPwdInput.addEventListener('input', () => {
		currentPwdInput.setCustomValidity('');
	});

	initEditPwdFields(newPwdInput, confirmPwdInput, userSelfServer, options.msgs);

	form.onsubmit = async function (event) {
		event.preventDefault();
		const response = await userSelfServer.renewPwd({nickOrAccount: account.value, currentPwd: currentPwdInput.value, password: newPwdInput.value});
		switch (response.result) {
		case 'updated' :
			confirmPwdInput.setCustomValidity('');
			newPwdInput.setCustomValidity('');
			confirmPwdInput.setCustomValidity('');
			showMsg(errorElt);
			if (options.renewPwdDone) {
				const form = options.renewPwdDone();
				if (form) initLoginForm(form, userSelfServer, authServer, {
					defaultAccount: account.value,
					startMessage: "Votre mot de passe a bien été renouvelé, authentifiez-vous avec ce dernier.",
					showRenewPwd: options.showRenewPwd,
					msgs: options.msgs
				})
			}
			break;
		case 'invalidToken' :
			currentPwdInput.setCustomValidity("Votre mot de passe actuel est incorrect.");
			// TODO Edge ne supporte pas reportValidity : https://caniuse.com/#feat=constraint-validation
			form.reportValidity();
			break;
		case 'failedInvalidDatas':
			const msg = response.secondaryResults && response.secondaryResults.msgs && response.secondaryResults.msgs[0];
			if (msg) newPwdInput.setCustomValidity(msg);
			else newPwdInput.setCustomValidity("Ce mot de passe n'est pas autorisé.");
			form.reportValidity();
			break;
		case 'otherFailure':
			showMsg(errorElt, "Le système de validation des mots de passe est indisponible. Veuillez essayer ultérieurement.", 'otherFailure', options.msgs);
			break;
		default :
			console.log(`Unknown auth result: '${response.result}'`);
			break;
		}
	};
}

/**
 * Ecran de définition d'un nouveau mot de passe, suite à une procédure de type "perte de mot de passe",
 * avec token sans demande de (re)saisie du mote de passe courant.
 * Champs du formulaire attendus:
 * - [optionnel] name=account
 * - name=newPwd
 * - name=confirmPwd
 *
 * Zone de message d'erreur : class="error"
 */
export interface OInitPwdFormOptions {
	/** Token de validation de la procédure (issu de l'URL envoyé dans le mail de perte de mot de passe par ex.) */
	updateToken: string

	/** Compte concerné. */
	account: string

	/**
	 * Callback lorsque l'initialisation du password a été réalisé.
	 * Si un formulaire est retourné, l'écran de login est réactivé avec ce formulaire.
	 */
	initPwdDone?: () => null | HTMLFormElement

	/** Personalisation des messages. */
	msgs?: OUserSelfFormsMsgs

	/** Interne. Pour permettre les cycles en cas de retour au login de renewPwdDone(). */
	showRenewPwd?: () => HTMLFormElement
}

export function initInitPwdForm(form: HTMLFormElement, userSelfServer: UserSelfSrv, authServer: AuthSrv, options: OInitPwdFormOptions) {
	checkForm(form);
	const accountInput = form.elements.namedItem('account') as HTMLInputElement;
	const newPwdInput = form.elements.namedItem('newPwd') as HTMLInputElement;
	const confirmPwdInput = form.elements.namedItem('confirmPwd') as HTMLInputElement;
	const errorElt = form.querySelector('.error') as HTMLElement;

	if (accountInput) {
		accountInput.value = options.account;
		accountInput.readOnly = true;
	}

	initEditPwdFields(newPwdInput, confirmPwdInput, userSelfServer, options.msgs);

	form.onsubmit = async function (event) {
		event.preventDefault();
		const response = await userSelfServer.pwdLostUpdate({nickOrAccount: options.account, updateToken: options.updateToken, password: newPwdInput.value});
		switch (response.result) {
		case 'updated' :
			confirmPwdInput.setCustomValidity('');
			newPwdInput.setCustomValidity('');
			confirmPwdInput.setCustomValidity('');
			showMsg(errorElt);
			if (options.initPwdDone) {
				const form = options.initPwdDone();
				if (form) initLoginForm(form, userSelfServer, authServer, {
					defaultAccount: options.account,
					startMessage: "Votre mot de passe a bien été initialisé, authentifiez-vous avec ce dernier.",
					showRenewPwd: options.showRenewPwd,
					msgs: options.msgs
				})
			}
			break;
		case 'tooOldToken' :
			showMsg(errorElt, getMsg("Votre demande de changement de mot de passe a expiré. Veuillez faire une nouvelle demande.", 'tooOldToken', options.msgs));
			break;
		case 'invalidToken' :
		case 'accountNotFound' :
			showMsg(errorElt, "Nous n'avons pas reconnu votre identité, l'URL de cette page obtenue à partir du mail que vous avez reçu est probablement incorrecte. Veuillez faire une nouvelle demande.");
			break;
		case 'accountDisabled' :
			//TODO
			// var vTs = pSecondaryResults ? parseInt(pSecondaryResults[0], 10) : 0;
			// if (vTs > (Date.now() - 5000)) {
			// 	var vDt = new Date(vTs);
			// 	this.setError(i18n.formatStr("Ce compte a été temporairement désactivé. Il sera de nouveau actif le %s vers %s.", vDt.toLocaleDateString(), vDt.toLocaleTimeString()));
			// } else {
			// 	this.setError("Ce compte est désactivé");
			// }
			showMsg(errorElt, "Votre demande ne peut pas être prise en compte car votre compte est désactivé.");
			break;
		case 'failedInvalidDatas':
			const msg = response.secondaryResults && response.secondaryResults.msgs && response.secondaryResults.msgs[0];
			if (msg) newPwdInput.setCustomValidity(msg);
			else newPwdInput.setCustomValidity("Ce mot de passe n'est pas autorisé.");
			form.reportValidity();
			break;
		case 'otherFailure':
			showMsg(errorElt, "Le système de validation des mots de passe est indisponible. Veuillez essayer ultérieurement.", 'otherFailure', options.msgs);
			break;
		default :
			console.log(`Unknown auth result: '${response.result}'`);
			break;
		}
	};
}


/** Messages personnalisables. */
interface OUserSelfFormsMsgs {
	accountNotFound: string
	datasNotAvailable: string
	instructionsSent: string
	otherFailure: string
	invalidPassword: string
	obsoletPassword: string
	accountDisabled: string
	tooOldToken: string
}


function initEditPwdFields(newPwdInput: HTMLInputElement, confirmPwdInput: HTMLInputElement, userSelfServer: UserSelfSrv, msgs: OUserSelfFormsMsgs) {

	newPwdInput.onchange = async function (this: HTMLInputElement) {
		if (this.value) {
			if (confirmPwdInput.value === this.value) {
				confirmPwdInput.setCustomValidity(''); //reset l'erreur de confirmation.
			}
			const response = await userSelfServer.checkPwd({password: this.value});
			if (response.result != 'ok') this.setCustomValidity(response.secondaryResults.msgs[0]);
			else this.setCustomValidity('');
		} else {
			this.setCustomValidity('');
		}
	};
	newPwdInput.onkeydown = resetValidity;

	confirmPwdInput.onchange = async function (this: HTMLInputElement) {
		if (this.value && this.value !== newPwdInput.value) {
			this.setCustomValidity("Cette confirmation ne correspond pas au nouveau mot de passe saisi.");
		} else {
			this.setCustomValidity('');
		}
	};
	confirmPwdInput.onkeydown = resetValidity;
}

function resetValidity(this: HTMLInputElement) {
	this.setCustomValidity('');
}

function getMsg(message: string, key?: keyof OUserSelfFormsMsgs, map?: OUserSelfFormsMsgs): string {
	return map && key && map[key] ? map[key] : message;
}

function showMsg(msgElt: HTMLElement, message?: string, key?: keyof OUserSelfFormsMsgs, map?: OUserSelfFormsMsgs, msgType?: string) {
	if (map && key && map[key]) message = map[key];
	if (msgElt) {
		DOM.setAttr(msgElt, "data-type", msgType);
		if (message) {
			msgElt.innerHTML = message;
			msgElt.hidden = false;
		} else {
			msgElt.innerHTML = '';
			msgElt.hidden = true;
		}
	} else if (message) {
		alert(message);
	}
}

function checkForm(form: HTMLFormElement) {
	//sécurité car si mauvaise config il y a un risque que le form affiche les params en QS dans l'URL
	// à la validation dont le password (car la config du form.onsubmit avec preventDefault sans effet)...
	if (!(form instanceof HTMLFormElement)) {
		document.body.textContent = "Configuration error";
		throw Error("form is not a HTMLFormElement : " + form);
	}
	if (form.method !== 'post') form.method = 'post'; //en cas d'exception avant l'init form.onsubmit, évite la publication du password dans l'URL
}
